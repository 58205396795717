<div class="bg-red-600 table-container">
  <nz-card [nzTitle]="tableNamePlural">
    @if(enableAdd){
    <div class="table-actions">
      <button nz-button nzType="primary" (click)="addAction()" class="px-6 py-2 text-white rounded-md border-none p-button-help bg-vendblocpurple hover:bg-vendblocpurpleDark focus:bg-vendblocpurple">
        <span nz-icon nzType="plus"></span>Add
      </button>
    </div>
    }

    <nz-table
      #basicTable
      [nzData]="tableData"
      [nzPageSize]="10"
      [nzShowSizeChanger]="true"
      [nzPageSizeOptions]="[10, 20, 50]"
      [nzShowTotal]="rangeTemplate"
      class="custom-table"
    >
      <thead>
        <tr class="text-lg">
          <th>#</th>
          @for(col of cols; track col.field) {
            @if(col.header !== '') {
              <th>{{ col.header }}
                @if(col.isSearchable){
                  <nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menu">
                    <span nz-icon style="font-size: 16px;color: #580c8b;" nzType="search"></span>
                  </nz-filter-trigger>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <div class="ant-table-filter-dropdown">
                      <div class="search-box">
                        <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValue" />
                        <button nz-button nzSize="small" nzType="primary" (click)="search(col.field)" class="search-button">Search</button>
                        <button nz-button nzSize="small" (click)="reset()">Reset</button>
                      </div>
                    </div>
                  </nz-dropdown-menu>
                }
              </th>
            }
          }
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        @for(data of basicTable.data; track data; let i = $index) {
        <tr class="text-base font-normal">
          <td>{{ i + 1 }}</td>
          @for(col of cols; track col.field) {
            @if(col.header !== '') {
                <td>
              @if(col.fieldType === FieldType.Image) {
                  <nz-avatar [nzSize]="64" [nzSrc]="data[col.field]"></nz-avatar>
              } @else if(col.fieldType === FieldType.Tag) {
                <span>
                  <nz-tag [nzColor]="getTagColor(data[col.field], col.tagType ?? '')">{{ data[col.field] }}</nz-tag>
                </span>
              } @else {
                <span>{{ data[col.field] }}</span>
              }
            </td>
            }
          }
          <td class="action-buttons">
            @if(enableShow) {
              <button nz-button nzType="default" (click)="showAction(i)">
                <span nz-icon nzType="eye"></span>View
              </button>
            }
            @if(enableOpen) {
              <button nz-button nzType="default" (click)="openAction(data.id)">
                <span nz-icon nzType="folder-open"></span>Open
              </button>
            }
            @if(enableEdit) {
              <button nz-button nzType="primary" (click)="editAction(i)">
                <span nz-icon nzType="edit"></span>Edit
              </button>
            }
            @if(enableDelete) {
              <button nz-button nzType="text" nzDanger (click)="deleteAction(data.id)">
                <span nz-icon nzType="delete"></span>
              </button>
            }
          </td>
        </tr>
        }
      </tbody>
    </nz-table>

    <ng-template #rangeTemplate let-range="range" let-total>
      {{ range[0] }}-{{ range[1] }} of {{ total }} {{ total === 1 ? tableNameSingular : tableNamePlural }}
    </ng-template>
  </nz-card>
</div> 