import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { CommonModule } from '@angular/common';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { FieldType, TableColumn } from '@utils/constants/enums';
import { getStatusTagColor } from '@utils/helpers/tag-helpers';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { FormsModule } from '@angular/forms';

interface TableColumnWithSearch extends TableColumn {
  visible?: boolean;
  searchValue?: string;
}

@Component({
  selector: 'app-zorro-table',
  templateUrl: './zorro-table.component.html',
  styleUrl: './zorro-table.component.css',
  standalone: true,
  imports: [CommonModule, FormsModule,NzTableModule, NzButtonModule, NzCardModule, NzAvatarModule, NzTagModule, NzIconModule,NzDropDownModule]
})
export class ZorroTableComponent {



  @Input() tableNamePlural: string = '';
  @Input() tableNameSingular: string = '';
  @Input() cols: TableColumnWithSearch[] = [];
  @Input() tableData: any[] = [];
  tableDataFiltered: any[] = [];
  @Input() enableAdd: boolean = false;
  @Input() enableShow: boolean = false;
  @Input() enableOpen: boolean = false;
  @Input() enableEdit: boolean = false;
  @Input() enableDelete: boolean = false;

  @Output() add = new EventEmitter<void>();
  @Output() show = new EventEmitter<number>();
  @Output() open = new EventEmitter<number>();
  @Output() edit = new EventEmitter<number>();
  @Output() delete = new EventEmitter<number>();

  private originalData: any[] = [];
  
  ngOnInit() {
    this.originalData = [...this.tableData];
    // Initialize search properties for each searchable column
    this.cols = this.cols.map(col => ({
      ...col,
      visible: false,
      searchValue: ''
    }));
  }

  visible = false;
  searchValue = '';

  FieldType = FieldType;

  addAction(): void {
    this.add.emit();
  }

  showAction(index: number): void {
    this.show.emit(index);
  }

  openAction(id: number): void {
    this.open.emit(id);
  }

  editAction(index: number): void {
    this.edit.emit(index);
  }

  deleteAction(id: number): void {
    this.delete.emit(id);
  }

  getTagColor(value: string, type: string): string {
    return getStatusTagColor(value, type);
  } 

  reset(): void {
    this.searchValue = '';
    this.visible = false;
    this.tableData = this.tableDataFiltered;
  }

  search(property: string): void {
    this.visible = false;
    const searchValuelower = this.searchValue.toLowerCase();
    this.tableDataFiltered = this.tableDataFiltered.length > 0 ? this.tableDataFiltered : this.tableData;
    if(typeof this.tableDataFiltered[0][property] === 'number'){
        this.tableData = this.tableDataFiltered.filter((item: any) => item[property].toString().includes(this.searchValue));
    }else{
      this.tableData = this.tableDataFiltered.filter((item: any) => item[property].toLowerCase().includes(searchValuelower));
    }
  }
} 